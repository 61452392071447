// saveCallQR.js
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";

export const Short_url_history = async (data) => {
 

  const docRef = doc(db, "short_url_history", data.trxid);
  const payload = {
    ...data,
    date_time: serverTimestamp(),
    device: navigator.userAgent,
    status: true,
    premium: true,
    favorites: false,
    category: "shorturl",
  };

  try {
    await setDoc(docRef, payload);
    console.log("History saved:", payload);
  } catch (err) {
    console.error("Error saving QR:", err);
  }
};
