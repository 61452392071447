// saveCallQR.js
import { doc, setDoc} from "firebase/firestore";
import { db } from "./firebase";
export const Short_url = async (data) => { 

  const docRef = doc(db, "short_url",data.uid); 
  const payload = {
    ...data, 
    date_time: new Date(),
    device: navigator.userAgent, 
    online_status: true, 
    status: true, 
    favorites: false, 
    category: "shorturl",
  };

  try {
    await setDoc(docRef, payload);
    
  } catch (err) {
    console.error("Error saving QR:", err);
     
  }
 
};
