import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import Cookies from 'js-cookie';
import { db } from "../firebase";  
import { getFirestore, doc, setDoc, getDoc,query,where, getDocs , updateDoc, collection } from "firebase/firestore";
import { IoLinkOutline } from "react-icons/io5";
import { IoQrCodeOutline } from "react-icons/io5";
import "./design_menu.css";
import { PiFolderSimpleLock } from "react-icons/pi";
import { Helmet } from "react-helmet"; 
import Designmenu from "./design_menu"; 
import './projects.css';
export default function Projects() {
      const [selected, setSelected] = useState('Projects');
        const navigate = useNavigate();
        const location = useLocation(); // Get current route
        const [stockData, setStockData] = useState([]);
        const [callqrData, setCallqrData] = useState([]);
        const [ShorturlDat, setShorturlData] = useState([]);
//date
const today = new Date();
const threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(today.getMonth() - 3);

const formatDate = (date) => date.toISOString().split('T')[0];

const minDate = formatDate(threeMonthsAgo);
const maxDate = formatDate(today);

//login 
 const [islogin, setislogin] = useState(false);
 const [guid, setguid] = useState('0');
 const [aguid, setaguid] = useState('0');
 useEffect(() => {
  const fetchUserData = async () => {
    const existingUser = Cookies.get("guid");
    const existingaguid = Cookies.get("aguid");
    if (existingUser) {
      setislogin(true);
      setguid(existingUser);
      setaguid(existingUser);
    } else {
      setguid("0");
      setislogin(false);
      navigate("/");
    }
  };
  fetchUserData();
}, []);

//callqr_test
useEffect(() => {
    if (!guid || guid === "0") return;

    const fetchStock = async () => {
      try {
        const stockQuery = query(
          collection(db, "callqr_test"),
          where("aguid", "==", guid)
        );
        const snapshot = await getDocs(stockQuery);
        if (!snapshot.empty) {
          const callqrArray = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCallqrData(callqrArray);
        } else {
            setCallqrData([]); // clear if nothing found
        }
      } catch (error) {
        console.error("Error fetching Callqr:", error);
      }
    };

    fetchStock();
  }, [guid]);
//short_url
useEffect(() => {
  if (!guid || guid === "0") return;

  const fetchStock = async () => {
    try {
      const stockQuery = query(
        collection(db, "short_url"),
        where("aguid", "==", guid)
      );
      const snapshot = await getDocs(stockQuery);
      if (!snapshot.empty) {
        const callqrArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShorturlData(callqrArray);
      } else {
          setShorturlData([]); // clear if nothing found
      }
    } catch (error) {
      console.error("Error fetching Callqr:", error);
    }
  };

  fetchStock();
}, [guid]);
//products_purchased
useEffect(() => {
    if (!guid || guid === "0") return;

    const fetchStock = async () => {
      try {
        const stockQuery = query(
          collection(db, "products_purchased"),
          where("aguid", "==", guid)
        );
        const snapshot = await getDocs(stockQuery);
        if (!snapshot.empty) {
          const stockArray = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setStockData(stockArray);
        } else {
          setStockData([]); // clear if nothing found
        }
      } catch (error) {
        console.error("Error fetching stock:", error);
      }
    };

    fetchStock();
  }, [guid]);
 
 
  return (
        <div>
          <Helmet>
          <title>Free QR Code Generator | CallQR | Create & Scan QR Codes Instantly</title>
        <meta name="description" content="Generate free QR codes instantly with CallQR. Create custom QR codes for websites, parking, events, business, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!" />
        <meta name="keywords" content="QR Code Generator, Free QR Codes, Generate QR Online, Custom QR Codes, QR Creator, QR Code for Business, Scan QR Instantly, CallQR App, Parking QR Codes, Event QR Tags, ID Card QR, QR for Membership, QR Code for Website, QR Code for Payments" />
    
            <meta name="author" content="callqr.com" /> 
            <meta property="og:title" content="Free QR Code Generator | CallQR | Create & Scan QR Codes Instantly"/>
            <meta property="og:description" content="Generate free QR codes instantly with CallQR. Create custom QR codes for websites, parking, events, business, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!"/>
            <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
            <meta property="og:url" content="https://callqr.com/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="CallQR" />
            <meta property="og:dateModified" content="2025-04-03" />
          </Helmet>
    
          <Designmenu/>
          <div className="design_container_roots">
            <div className='project_tabs'>
            <div
     onClick={() => setSelected('Projects')}
     className={`design-menu-button ${selected === 'Projects' ? 'active' : ''}`}
          title="Projects"
   >    
     <span className="button-text">Projects</span>
              </div>
              <div
     onClick={() => setSelected('Short URL')}
     className={`design-menu-button ${selected === 'Short URL' ? 'active' : ''}`}
          title="Short URL"
   >    
     <span className="button-text">Short URL</span>
              </div>
                   <div
     onClick={() => setSelected('Stock')}
     className={`design-menu-button ${selected === 'Stock' ? 'active' : ''}`}
          title="Stock"
   >    
     <span className="button-text">Stock</span>
   </div>
  
              {selected != 'Stock' && (
              <div className='date-div'>
              <input class='date-input' type='date' name='startDate'
         min="2024-01-07" max="2024-04-07" value="2024-01-07" />
  <input class='date-input' type='date' name='endDate'
         min="2024-01-07" max="2024-04-07" value="2024-04-07" />
              </div>
              )}
            </div>
      
  {selected === 'Projects' && (
    //Projects
            <div className='data_div'> 
      {callqrData.length > 0 ? (
        <table border="1" cellPadding="2">
          <thead>
            <tr>
              <th>Date</th> 
              <th>Producat</th>       
              <th>Description</th>
              <th>Online</th>
              <th>Status</th> 
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {callqrData.map((item) => (
                <tr title='Click To Edit'  key={item.id} onClick={() => navigate('/design?uid=' + item.uid)} >
               <td width={140}><a>{item.date_time?.toDate().toLocaleString()}</a>
          
               </td>
               <td >{item.smartid_name+"/ "+item.name}</td>
                <td><b>{item.description_text}</b></td>
                <td width={70}>
  <b style={{ color: item.online_status ? 'green' : 'red' }}>
    {item.online_status ? 'Online' : 'Offline'}
  </b>
</td>
<td width={70}>
  <b style={{ color: item.status ? 'black' : 'red' }}>
    {item.status ? 'Activate' : 'Deactivate'}
  </b>

</td>
 
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No projects found.</p>
      )}
    </div>
)}
  {selected === 'Short URL' && (
    //Short URL
            <div className='data_div'> 
      {ShorturlDat.length > 0 ? (
        <table border="1" cellPadding="2">
          <thead>
            <tr>
              <th>Date</th> 
              <th>Short URL</th>       
              <th>Alias</th>
              <th>Online</th>
              <th>Status</th> 
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {ShorturlDat.map((item) => (
                <tr title='Click To Edit'  key={item.id} onClick={() => navigate('/design?uid=' + item.uid)} >
               <td width={140}><a>{item.date_time?.toDate().toLocaleString()}</a>
          
               </td>
               <td >{item.shorturl}</td>
                <td><b>{item.alias}</b></td>
                <td width={70}>
  <b style={{ color: item.online_status ? 'green' : 'red' }}>
    {item.online_status ? 'Online' : 'Offline'}
  </b>
</td>
<td width={70}>
  <b style={{ color: item.status ? 'black' : 'red' }}>
    {item.status ? 'Activate' : 'Deactivate'}
  </b>

</td>
 
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No projects found.</p>
      )}
    </div>
)}
      {selected === 'Stock' && (
            <div className='data_div'> 
      {stockData.length > 0 ? (
        <table border="1" cellPadding="2">
          <thead>
            <tr>
              <th>Date</th>              
              <th>Product Name</th>
              <th>Stock</th>
              <th>Price</th>
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {stockData.map((item) => (
              <tr key={item.id} >
               <td width={160}>{item.date_time?.toDate().toLocaleString()}</td>
           
                <td><b>{item.name}</b></td>
                <td><b>{item.stock}</b></td>
                <td>{item.price}/-</td>
                {/* Add more cells depending on data structure */}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No stock found.</p>
      )}
    </div>
)}

          </div>
</div>
    );
}
