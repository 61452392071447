import React, { createContext, useContext, useState, useCallback } from 'react';
import './ToastContext.css';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {

  const [toast, setToast] = useState({ message: '', visible: false });

  const showToast = useCallback((message) => {
    setToast({ message, visible: true });

    setTimeout(() => {
      setToast({ message: '', visible: false });
    }, 3000);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>    

            {children}
      {toast.visible && (
        <div className="errotoast">
          {toast.message}
        </div>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
