import React, { useState, useRef, useEffect } from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import Designmenu from "./design_menu"; 
import './design-qrcode.css';
import { db } from "../firebase";  
import { useNavigate, useLocation } from "react-router-dom"; 
import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';
export default function Design() {
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
       const navigate = useNavigate();
  //login 
   const [islogin, setislogin] = useState(false);
   const [guid, setguid] = useState('0');
 
   const [aguid, setaguid] = useState('0');
   const [loginkey, setloginkey] = useState('0');
   useEffect(() => {
    const fetchUserData = async () => {
      const existingUser = Cookies.get("guid");
      const existingaguid = Cookies.get("aguid");
      const existingloginkey = Cookies.get("loginkey");
      if (existingUser) {
        setislogin(true);
        
      } else {
        setguid("0");
        setislogin(false);
        navigate("/");
      }
    };
    fetchUserData();
  }, []);

  return (
    
    <div>

      <Helmet>
        <title>Design QRCodes | Generate Free QR Codes Online | QR Code Creator | Scan Any QR, Anytime, Anywhere! download Callqr app</title>
        <meta name="description" content="CallQR.com | Design QR codes , Generate free QR codes instantly. Create custom QR codes for websites, text, or contact information." />
        <meta name="keywords" content="CallQR,Design QRCodes, secure communication, vehicle parking, QR tags, event cards, business tools, membership, attendance, spam-free calls" />
        <meta name="author" content="callqr.com" />

 
        <meta property="og:title" content="Design QRCodes | Generate Free QR Codes Online | QR Code Creator | Scan Any QR, Anytime, Anywhere! download Callqr app"/>

<meta property="og:description" content="CallQR.com | Design QR codes , Generate free QR codes instantly. Create custom QR codes for websites, text, or contact information"/>

<meta property="og:keywords" content="Design QRCodes, Free QR Code Generator, QR Code Creator, QR Code Maker, Generate QR Codes Online"/>
        <meta property="og:image" content="https://callqr.com/assets/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:dateModified" content="2024-09-01" />
      </Helmet>
      <Designmenu/>
      <div className="design_container_roots">
      <h1 className="contact-title">{uid}</h1>
      </div>
</div>
    );
}
