import React, { useState } from "react";
import "./SwitchStyle.css";

const Switch = ({ isOn, handleToggle, onColor, offColor }) => {
  return (
    <div
      className="switch"
      onClick={handleToggle}
      style={{ backgroundColor: isOn ? onColor : offColor }}
    >
      <div className={`switch-handle ${isOn ? "Premium" : "Free"}`}></div>
      <span className="switch-label">{isOn ? "Premium" : "Free"}</span>
    </div>
  );
};

export default Switch;
