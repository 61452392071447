// src/home_pages/home.jsx
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import Userdata from "./user_data";
import Homedata from "./home_data";
import Lodinging from "../components/lodinging";
import { Shorturlredirect } from "../pages/short_url_redirect";

export default function Home() {
  const { id } = useParams(); 
  const [docData, setDocData] = useState(null);
  const [redirectData, setRedirectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      const popup = document.getElementById("WhatsAppQueryPopup");

      if (!id) {
        setLoading(false);
        if (popup) popup.style.display = "block";
        return;
      } else {
        if (popup) popup.style.display = "none";
      }

      try {
        const short_urlDocRef = doc(db, "short_url", id);
        const short_urlDocSnap = await getDoc(short_urlDocRef);
        if (short_urlDocSnap.exists()) {
          const data = short_urlDocSnap.data();
          if (data?.status === true && data?.premium === true) {
            setRedirectData({ ...data, uid: id });
            return;
          } else if (data?.status === true) {
            window.location.href = data.loginurl;
            return;
          }
        }

        const callqrRef = doc(db, "callqr", id);
        const callqrSnap = await getDoc(callqrRef);
        if (callqrSnap.exists()) {
          const data = callqrSnap.data();
          if (data?.status === true) {
            setDocData(data);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="container_roots">
        <Lodinging />
      </div>
    );
  }

  if (redirectData) {
    return <Shorturlredirect data={redirectData} />;
  }

  return (
    <div className="contact_content">
      {id && !error && docData ? (
        <Userdata data={docData} />
      ) : (
        <Homedata />
      )}
    </div>
  );
}
