// src/pages/short_url_redirect.js
import { useEffect, useState } from "react";
import { Short_url_history } from "../Short_url_history";

export function Shorturlredirect({ data }) {
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((ipData) => setIpAddress(ipData.ip))
      .catch((err) => console.error("IP fetch error:", err));
  }, []);

  const generatedStrings = new Set();

  function generateUniqueRandomString(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result;
    do {
      result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
    } while (generatedStrings.has(result));
    generatedStrings.add(result);
    return result;
  }

  useEffect(() => {
    const handleRedirect = async () => {
      if (!ipAddress || !data?.premium) return;

      await Short_url_history({
        trxid: generateUniqueRandomString(),
        loginurl: data.loginurl,
        shorturl: data.shorturl,
        alias: data.alias || "",
        uid: data.uid,
        ip: ipAddress,
      });

      window.location.href = data.loginurl;
    };

    handleRedirect();
  }, [ipAddress, data]);

  return null;
}
