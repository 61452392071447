import Home from './home_pages/home';
import Contactus from './pages/contact-us';
import DownloadPage from './pages/download';
import Termsofservice from './pages/terms-of-service';
import Helpcenter from './pages/help-center';
import Privacypolicy from './pages/privacy-policy';
import Disclaimer from './pages/disclaimer';
import Sitemap from './pages/sitemap';
import Forbusiness from './pages/for-business';
import Price from './products_root/price';
import Features from './pages/features';
import Design from './design_root/design'; 
import Security from './pages/security';
import Userguide from './pages/user_guide';
import Shorturl from './design_root/short-url';
import Order from './products_root/order';
import Settings from './profile_roots/settings';
import Careers from './pages/careers';
import Deleteaccount from './pages/delete-account';
import Search from './pages/search';
import Troubleshoot from './pages/troubleshoot';
import FaqPage from './pages/faq';
import AboutUs from './pages/about-us';
import Projects from './design_root/projects';
import SecurityAdvisories from './pages/security-advisoroes';
import { Shorturlredirect } from './pages/short_url_redirect';
export const appRoutes = [
  { path: '/', name: 'Home', element: <Home /> },
  { path: '/short_url_redirect', name: 'Shorturlredirect', element: <Shorturlredirect /> },
  { path: '/about-us', name: 'About Us', element: <AboutUs /> },
  { path: '/projects', name: 'Projects', element: <Projects /> },
  { path: '/short-url', name: 'Short URL', element: <Shorturl /> },
  { path: '/order', name: 'Order', element: <Order /> },
  { path: '/features', name: 'Features', element: <Features /> },
  { path: '/price', name: 'Pricing', element: <Price /> },
  { path: '/design', name: 'Design', element: <Design /> },
  { path: '/contact-us', name: 'Contact Us', element: <Contactus /> },
  { path: '/for-business', name: 'For Business', element: <Forbusiness /> },
  { path: '/download', name: 'Download', element: <DownloadPage /> },
  { path: '/help-center', name: 'Help Center', element: <Helpcenter /> },
  { path: '/terms-of-service', name: 'Terms of Service', element: <Termsofservice /> },
  { path: '/privacy-policy', name: 'Privacy Policy', element: <Privacypolicy /> },
  { path: '/disclaimer', name: 'Disclaimer', element: <Disclaimer /> },
  { path: '/security-advisoroes', name: 'Security Advisories', element: <SecurityAdvisories /> },
  { path: '/sitemap', name: 'Sitemap', element: <Sitemap /> },
  { path: '/security', name: 'Security', element: <Security /> },
  { path: '/user_guide', name: 'User Guide', element: <Userguide /> },
  { path: '/settings', name: 'Settings', element: <Settings /> },
  { path: '/careers', name: 'Careers', element: <Careers /> },
  { path: '/delete-account', name: 'Delete Account', element: <Deleteaccount /> },
  { path: '/troubleshoot', name: 'Troubleshoot', element: <Troubleshoot /> },
  { path: '/search', name: 'Search', element: <Search /> },
  { path: '/faq', name: 'FAQs', element: <FaqPage /> },
];
