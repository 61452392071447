// saveCallQR.js
import { doc, setDoc} from "firebase/firestore";
import { db } from "./firebase";
export const Save_callqr = async (data) => { 

  const docRef = doc(db, "callqr_test", data.uid); 
  const payload = {
    ...data,
    name_date_time: new Date(),
    about_date_time: new Date(),
    activation_datetime: new Date(),
    date_time: new Date(),
    device: navigator.userAgent,
    ip: "CLIENT_IP", // or remove if you don't have IP
    online_status: true,
    activation_status: true,
    scan_verified: true,
    status: true,
    quick_privacy_switch: false,
    contact_privacy: false,
    favorites: false,
    away_message: "Thank you for reaching out. We will get back to you as soon as possible.",
    away_message_status: false,
    greeting_message: "We’re glad to have you here.",
    greeting_message_status: false,
    category: "smartid",
  };

  try {
    await setDoc(docRef, payload);
    
  } catch (err) {
    console.error("Error saving QR:", err);
     
  }
 
};
